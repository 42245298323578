import React from "react";

import type { ReactNode } from "react";

import Error from "../pages/_error";

export const UseErrorHandler = ({
  errorCode,
  children
}: {
  errorCode?: number;
  children: ReactNode;
}) => <>{errorCode ? <Error statusCode={errorCode} /> : children}</>;
