import React from "react";
import * as Sentry from "@sentry/nextjs";

import type { NextPageContext } from "next";

import { LayoutWithoutSidebar } from "../layout/Layout";

export const Error500View = () => {
  return (
    <LayoutWithoutSidebar withTopAd={false}>
      <div className="errorpage">
        <div className="errorpage-icon">
          <i className={`icon icon--500`}></i>
        </div>
        <h1 className="fs--h1 errorpage-title">Oj! Något har gått fel.</h1>
        <div className="u-doublefadehr--after"></div>
        <div className="errorpage-message">
          <p>
            Vi ber om ursäkt! Något fungerar inte hos oss. Våra tekniker tittar
            på problemet
          </p>

          <p>
            Kontakta oss i så fall gärna på
            <a className="a a--secondary" href="mailto:info@omni.se">
              {" "}
              info@omni.se
            </a>
          </p>
        </div>
      </div>
    </LayoutWithoutSidebar>
  );
};

export const Error404View = () => {
  return (
    <LayoutWithoutSidebar withTopAd={false}>
      <div className="errorpage">
        <div className="errorpage-icon">
          <i className={`icon icon--404`}></i>
        </div>
        <h1 className="fs--h1 errorpage-title">
          Oops. <br /> Den här sidan finns inte.
        </h1>
        <div className="u-doublefadehr--after"></div>
        <div className="errorpage-message">
          <p>
            Antingen är länken du klickat på inte giltig, eller så behöver du
            kontrollera att du skrivit in rätt adress.
          </p>
          <p> Känns det som att felet är vårt?</p>
          <p>
            Kontakta oss i så fall gärna på
            <a className="a a--secondary" href="mailto:info@omni.se">
              {" "}
              info@omni.se
            </a>
          </p>
        </div>
      </div>
    </LayoutWithoutSidebar>
  );
};

enum ErrorCode {
  notFound = 404,
  internalError = 500
}

const errorViews = {
  404: Error404View,
  500: Error500View
};

function getErrorMessage(errorCode: ErrorCode) {
  return errorViews[errorCode];
}

Error.getInitialProps = async (contextData: NextPageContext) => {
  await Sentry.captureUnderscoreErrorException(contextData);
  const statusCode = contextData.res
    ? contextData.res.statusCode
    : contextData.err
    ? contextData.err.statusCode
    : 404;
  return { statusCode };
};

function Error({ statusCode }: { statusCode: number }) {
  console.error("errorcode is ", statusCode);
  const ErrorComponent = getErrorMessage(statusCode);
  return <ErrorComponent />;
}

export default Error;
