import { useEffect, useRef } from "react";
import { dequal } from "dequal";

import type { DependencyList, EffectCallback } from "react";

/**
 * @Link https://github.com/kentcdodds/use-deep-compare-effect/blob/master/src/index.js#L21
 * @param deps
 */
function useDeepCompareMemoize(deps?: DependencyList) {
  const ref = useRef(undefined as DependencyList | undefined);

  if (!dequal(deps, ref.current)) {
    ref.current = deps;
  }

  return ref.current;
}

export function useWhenChangedEffect(
  effect: EffectCallback,
  deps?: DependencyList
) {
  const shouldIgnoreRunEffectOnFirstRender = useRef(true);
  useEffect(() => {
    if (shouldIgnoreRunEffectOnFirstRender.current) {
      shouldIgnoreRunEffectOnFirstRender.current = false;
      return;
    }
    return effect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useDeepCompareMemoize(deps));
}
