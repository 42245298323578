import url from "url";

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { useRouter } from "next/router";

import type { ReactNode } from "react";
import type { NextRouter } from "next/router";
import type {
  ArticlesResponse,
  Category,
  SortType,
  Group,
  Article,
  CategoriesResponse,
  SearchArticlesParams
} from "../../client/";

import { ArticlesFeedContext } from "../../contexts/ArticlesFeedContext";
import { fetchArticles, isGroupWithBox } from "../../client/";
import { useWhenChangedEffect } from "../../hooks/useWhenChangedEffect";
import { TrackingContext } from "../../contexts/TrackingContext";
import { isArticle } from "../../helpers";
import { getConfig } from "../../config";
import { useGlobalStore } from "../GlobalStateContainer/store";

const getPageContext = (pathname: string): { [key: string]: boolean } => ({
  category: pathname === "/category",
  latest: pathname === "/latest",
  topic: pathname === "/topic",
  bookmarks: pathname === "/bookmarks"
});

const getParams = (r: NextRouter, allCategories: CategoriesResponse) => {
  let category: Category | undefined;
  let sort: SortType | undefined;
  let topics: string[] | undefined;

  const context = getPageContext(r.pathname);
  const activeContext = Object.keys(context).filter(c => context[c]);
  switch (activeContext[0]) {
    case "category":
      category = allCategories.find(c => c.slug === r.query.slug);
      break;
    case "latest":
      sort = "latest";
      break;
    case "topic":
      topics = [r.query.topicId as string];
      sort = "latest";
      break;
    case "bookmarks":
      sort = "bookmarks";
      break;
    default:
      break;
  }

  return { category, sort, topics };
};

const omitRepeated = (articles: Array<Group>) => {
  const index: { [key: string]: boolean } = {};
  return articles.map(group => {
    if (isGroupWithBox(group)) {
      if (index[group[0].name]) {
        return [];
      }
      index[group[0].name] = true;
      return group;
    } else {
      const firstArticle: Article = group.filter(
        item => item.type === "Article"
      )[0] as Article;
      if (!firstArticle) {
        return group;
      }
      if (index[firstArticle.article_id]) {
        return [];
      }
      index[firstArticle.article_id] = true;
      return group;
    }
  });
};

const getTrackingArticle = (groups: Group[]): Article | undefined => {
  const components = groups.reduce((acc, val) => acc.concat(val), []);

  for (let i = 0; i < components.length; i++) {
    if (isArticle(components[i])) {
      return components[i] as Article;
    }
  }

  return undefined;
};

function getCategory(
  router: NextRouter,
  categories: Category[]
): Category | undefined {
  const categorySlug = router.query.slug;
  return categories.find(category => category.slug === categorySlug);
}

export function FeedContainer({
  children,
  articles: initArticles
}: {
  children: ReactNode;
  articles: ArticlesResponse;
}) {
  const [articles, setArticles] = useState(initArticles.articles);
  const [searchParams, setSearchParams] = useState(initArticles.searchParams);
  const [hasMore, setHasMore] = useState(true);
  const router = useRouter();
  const nextFeedUrl = useRef(initArticles.links?.next || "");
  const {
    NEXT_PUBLIC_PREMIUM_NEWSROOM,
    NEXT_PUBLIC_DOMAIN,
    NEXT_PUBLIC_PULSE_CLIENT_ID
  } = getConfig();
  const shouldRefetchArticles = useRef(false);
  const { trackViewFeed, isPulseReady } = useContext(TrackingContext);
  const {
    exchangeToken,
    isLoggedIn,
    subscriptionsInfo: { isPremium },
    personalizedContent: { usermix },
    categories,
    allCategories
  } = useGlobalStore();
  const previousIsPulseReady = useRef<boolean>();

  // Used to refresh all states when for example switching categories
  useWhenChangedEffect(() => {
    nextFeedUrl.current = initArticles.links?.next || "";
    setSearchParams(initArticles.searchParams);
    shouldRefetchArticles.current = false;
    setArticles(initArticles.articles);
    setHasMore(true);
  }, [initArticles]);

  if (isPulseReady !== previousIsPulseReady.current) {
    if (isPulseReady) {
      const article = getTrackingArticle(articles);
      const category = getCategory(router, categories);
      trackViewFeed({
        isLoggedIn,
        router,
        article,
        category,
        NEXT_PUBLIC_DOMAIN,
        NEXT_PUBLIC_PULSE_CLIENT_ID
      });
    }

    previousIsPulseReady.current = isPulseReady;
  }

  useWhenChangedEffect(() => {
    const params = getParams(router, allCategories);

    setSearchParams(prev => ({
      ...prev,
      ...params,
      offset: 0,
      articleOffset: 0,
      usermix,
      exchangeToken
    }));
    setHasMore(true);
    shouldRefetchArticles.current = false;
  }, [usermix]);

  useEffect(() => {
    if (NEXT_PUBLIC_PREMIUM_NEWSROOM && isPremium && exchangeToken) {
      setSearchParams(prev => ({
        ...prev,
        offset: 0,
        articleOffset: 0,
        exchangeToken: exchangeToken
      }));
      setHasMore(true);
      shouldRefetchArticles.current = true;
    }
  }, [NEXT_PUBLIC_PREMIUM_NEWSROOM, isPremium, exchangeToken]);

  useEffect(() => {
    const abortController = new AbortController();

    const refreshFeed = async (searchParams: SearchArticlesParams) => {
      if (
        searchParams?.category &&
        typeof searchParams?.category === "string"
      ) {
        console.error(`!!!CATEGORY IS STRING: ${searchParams.category}`);
        return;
      }

      const response = await fetchArticles({
        ...searchParams,
        signal: abortController.signal
      });
      nextFeedUrl.current = response.links?.next || "";

      if (response.articles.length === 0) {
        setHasMore(false);
      }

      if (searchParams.offset === 0) {
        setArticles(response.articles);
      } else {
        setArticles(articles =>
          omitRepeated([...articles, ...response.articles])
        );
      }

      shouldRefetchArticles.current = false;
    };

    if (!shouldRefetchArticles.current) {
      return;
    }

    refreshFeed(searchParams).catch(e =>
      console.error(`Error when feed container: ${e}`)
    );

    return () => {
      abortController.abort();
    };
  }, [searchParams]);

  const nextPage = useCallback(async () => {
    const offsets = getNextOffsets(nextFeedUrl.current);
    if (!offsets) {
      return;
    }
    const params = getParams(router, allCategories);

    setSearchParams(prev => ({
      ...prev,
      ...params,
      usermix: usermix,
      offset: offsets.offset,
      articleOffset: offsets.articleOffset,
      exchangeToken
    }));

    shouldRefetchArticles.current = true;
  }, [router, allCategories, usermix, exchangeToken]);

  const articlesFeedContext = {
    articles,
    nextPage,
    hasMore
  };

  const ArticlesFeed = (
    <ArticlesFeedContext.Provider value={articlesFeedContext}>
      {children}
    </ArticlesFeedContext.Provider>
  );

  return ArticlesFeed;
}

function getNextOffsets(feedUrl: string) {
  if (!feedUrl) {
    return;
  }
  const urlObject = url.parse(feedUrl, true);

  const offset = Number(urlObject.query.offset);
  const articleOffset = Number(urlObject.query.articleOffset);
  return {
    offset,
    articleOffset
  };
}
